/**
 * Created by osirvent on 04/04/2017.
 */
angular
    .module('annexaApp')
    .component('annexaAdminBase', {
        templateUrl: './components/admin/annexa-admin-base/annexa-admin-base.html',
        controller: ['HeaderService', '$rootScope', '$state', 'Language', 'TableFilterFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', '$http', 'AnnexaModalFactory', 'RestService', 'dialogs', 'SecFactory', function (HeaderService, $rootScope, $state, Language, TableFilterFactory, $filter, globalModals, AnnexaFormlyFactory, $http, AnnexaModalFactory, RestService, dialogs, SecFactory) {
            var vm = this;

            $rootScope.$on('annexaAdminBaseReloadTable', function(event, args) {
                vm.tableDefinition.reloadInternalData(false, true);
            });

            vm.alerts = [];

            if(!vm.headButtons) {
                if(vm.isAudit){
                    if(!vm.isBox) {
                        vm.headButtons = [
                            new HeadButtonSearch("#tableFilter")
                        ]
                    }
                }else {
                    vm.headButtons = [
                        new HeadButtonSearch("#tableFilter"),
                        new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#' + vm.id, 'fa fa-plus', vm.newLiteral, undefined, 'newElement')
                    ]
                }
            } else {
                vm.headButtons.unshift(new HeadButtonSearch("#tableFilter"));
            }

            HeaderService.onChangeState(vm.containerScope, function (message) {
                if(message.state.name == vm.stateName) {
                    $rootScope.subHeadButtons = vm.headButtons;
                }
            });

            HeaderService.changeState($state.current);

            if(!vm.containerScope.newElement) {
                vm.containerScope.newElement = function () {
                    var modal = undefined;
                    if (vm.newModal) {
                        modal = vm.newModal;
                        modal.title = vm.newLiteral;
                    } else {
                        modal = globalModals.adminBaseNew;
                    }
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {};
                    modal.annexaFormly.options = {};
                    AnnexaFormlyFactory.showModal('modalNewAdminBase', modal, vm.saveElement, false);
                };
            }

            if(!vm.saveElement) {
                vm.saveElement = function (element) {
                    var modal = this;

                    var model = {
                        language1: element.annexaFormly.model.modal_body.language1,
                        language2: element.annexaFormly.model.modal_body.language2 ? element.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                        language3: element.annexaFormly.model.modal_body.language3 ? element.annexaFormly.model.modal_body.language1 : 'NotTranslated',
                    };

                    RestService.insert(vm.apiUrl, model)
                        .then(function (data) {
                            vm.tableDefinition.reloadInternalData(false, true);
                            modal.close();
                    }).catch(function (error) {
                            modal.alerts.push({msg: $filter('translate')('global.errors.unknownNew')})
                    });
                }
            }

            if(!vm.filter) {
                if(vm.isNotLanguage){
                	vm.filter = [];
                }else{
	            	vm.filter = [
	                    {id: Language.getActiveColumn(), type: 'text', order: 0, label: 'global.literals.name'}
	                ];
                }
            }

            var getFilterCall = function () {
                var filterCall = TableFilterFactory.getFilterCall(vm.filter);
                if(vm.addToFilter && vm.addToFilter.filter && vm.addToFilter.filter.length > 0) {
                    _.forEach(vm.addToFilter.filter, function (property) {
                        if (property && property.name && property.value && !filterCall[property.name]) {
                            filterCall[property.name] = property.value;
                        }
                    });
                }
                return filterCall;
            }

            var getFilterCallAux = function() {
                var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filter, true);
                if(!vm.isNotLanguage){
                	filterCallAux.langColumn = Language.getActiveColumn();
                }
                if(vm.addToFilter && vm.addToFilter.filterAux && vm.addToFilter.filterAux.length > 0) {
                    _.forEach(vm.addToFilter.filterAux, function (property) {
                        if (property && property.name && property.value && !filterCallAux[property.name]) {
                            filterCallAux[property.name] = property.value;
                        }
                    });
                }
                return filterCallAux;
            }

            var columns = [];
            columns.push({ id: 'id', width: '6%', column: new DatabaseTranslatedColumn($filter, 'id', 'id') });
            if(!vm.isAudit && !vm.isNotLanguage){
                columns.push({ id: Language.getActiveColumn(), column: new DatabaseTranslatedColumn($filter, 'global.literals.name', Language.getActiveColumn()) });
            }
            if(vm.additionalColumns) {
                _.forEach(vm.additionalColumns, function (value) {
                    columns.push(value);
                });
            }

            if(!vm.isAudit){
                columns.push({ id: 'id', width: '12%', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), [ new ActionButton('global.literals.remove', 'deleteElement([data])') ])});
            }

            vm.tableDefinition = {
                id: 'tableAdminBase',
                origin: vm.origin,
                objectType: vm.objectType,
                sortName: ((vm.isNotLanguage)?'id':Language.getActiveColumn()),
                sort: [[((vm.isNotLanguage)?0:1), 'asc']],
                filterCall: getFilterCall(),
                filterCallAux: getFilterCallAux(),
                filterCallFunc: getFilterCall,
                filterCallAuxFunc: getFilterCallAux,
                columns: columns,
                containerScope: vm.containerScope,
                useTableObjects: vm.useTableObjects || false
            }

            if(!vm.containerScope.viewElement) {
                vm.containerScope.viewElement = function (id) {
                    RestService.findOne(vm.objectType, id)
                        .then(function (data) {
                            var modal = globalModals.adminBaseEdit;
                            modal.title = vm.editLiteral;
                            modal.alerts = [];
                            modal.data = JSOG.decode(data.data);
                            modal.languageColumn = Language.getActiveColumn();
                            modal.updateElement = function (val, prop) {
                                RestService.update(vm.apiUrl + '/' + id, val)
                                    .then(function (data) {
                                        vm.tableDefinition.reloadInternalData(false);
                                }).catch(function (error) {
                                    //Empty
                                });
                            };

                            AnnexaModalFactory.showModal('modalElementEdit', modal);
                        }).catch(function (error) {
                        console.log(error);
                    })
                }
            }

            vm.containerScope.deleteElement = function (id) {
                RestService.checkDelete(vm.apiUrl + '/check_delete/' + id)
                    .then(function(data) {
                        if(data) {
                            var dlg = dialogs.confirm(
                                $filter('translate')('global.literals.clear'),
                                $filter('translate')('global.literals.confirmDeleteElement'),
                                {
                                    animation: true,
                                    background: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            );

                            dlg.result.then(function(btn) {
                                RestService.delete(vm.apiUrl + '/' + id)
                                    .then(function(data) {
                                    	if(vm.objectType == 'OrganMemberGlobal' && SecFactory.thirdsAdded){
                            				if(id && vm.tableDefinition && vm.tableDefinition.list && $linq(vm.tableDefinition.list).firstOrDefault(undefined, "x => x.id =="+id) != undefined){
                            					var attendee = $linq(vm.tableDefinition.list).firstOrDefault(undefined, "x => x.id =="+id);
                            					if(attendee && attendee.attendee && attendee.attendee.id){
	                            					var idAttendee = attendee.attendee.id;
	                            					var index = $linq(SecFactory.thirdsAdded).indexOf("x => x =="+idAttendee )
	                            					if(index > 0){
	                            						SecFactory.thirdsAdded.splice(index, 1);
	                            					}
                            					}
                            				}
                                    	}
                                        vm.tableDefinition.reloadInternalData(true,true);
                                }).catch(function (error) {
                                    vm.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
                                });
                            }, function (btn) {});
                        } else {
                            var ok = dialogs.notify(
                                '',
                                $filter('translate')(vm.checkDeleteMsg),
                                {
                                    animation: true,
                                    backdrop: 'static',
                                    keyboard: true,
                                    size: ''
                                }
                            )
                        }
                }).catch(function (error) {
                    vm.alerts.push({ msg: 'global.errors.unknownDelete' });
                });
            }
        }],
        bindings: {
            id: '@',
            stateName: '@',
            newLiteral: '@',
            editLiteral: '@',
            origin: '@',
            objectType: '@',
            containerScope: '=',
            apiUrl: '@',
            newModal: '=?',
            saveElement: '=?',
            checkDeleteMsg: '@',
            filter: '=?',
            additionalColumns: '=?',
            headButtons: '=?',
            useTableObjects: '=?',
            isAudit: '=?',
            isBox: '=?',
            addToFilter: '=?',
            isNotLanguage: '=?'
        }
    });