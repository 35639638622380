angular
    .module('annexaApp')
    .component('annexaNotificationStatuses', {
        templateUrl: './components/admin/annexa-notification-statuses/annexa-notification-statuses.html',
        controller: ['$rootScope', 'Language', '$filter', 'RestService', function($rootScope, Language, $filter, RestService) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.requiredText = $filter('translate')('global.validation.required');
            vm.isNew = false;
            vm.inserted = undefined;

            vm.globalStatuses = [
                { id: 'PENDING', name: 'global.literals.PENDING' },
                { id: 'SEND', name: 'global.literals.SEND' },
                { id: 'COMPLETE', name: 'global.literals.COMPLETE' }
            ];

            if(!vm.type) {
                vm.type = { statuses: [] };
                vm.isNew = true;
            }

            vm.updateStatus = function (rowformprofile) {
                _.forEach(vm.type.statuses, function(value, key) {
                    var languageNull = true;

                    _.forEach($rootScope.app.languagedef, function(val) {
                        if(value[val.column]) {
                            languageNull = false;
                        }
                    });

                    if(languageNull) {
                        vm.type.statuses.splice(key, 1);
                    }
                });
            }

            vm.removeStatus = function(index) {
                if(!vm.isNew) {
                    RestService.delete('./api/reg/notification_status/' + vm.type.statuses[index].id)
                        .then(function(data) {
                            vm.type.statuses.splice(index, 1);
                        }).catch(function(error) {
                            //Empty
                    });
                } else {
                    vm.type.statuses.splice(index, 1);
                }
            }

            vm.addStatus = function() {
                vm.inserted = {
                    language1: undefined,
                    language2: undefined,
                    language3: undefined,
                    allowEvidence: false
                }

                if(!vm.isNew) {
                    vm.inserted.type = vm.type;
                }

                vm.type.statuses.push(vm.inserted);
            }

            vm.editStatus = function (index) {
                //Empty
            }

            vm.checkName = function ($data, status, object) {
                var languageOk = true;

                _.forEach($rootScope.app.languagedef, function(val) {
                    if(!$data[val.column]) {
                        languageOk = false;
                    }
                });

                if(!languageOk) {
                    return vm.requiredText;
                } else {
                    status = $data;

                    if(vm.isNew) {
                        $rootScope.$broadcast('AnnexaNotificationStatusAdded', { statuses: vm.type.statuses });
                    }
                }
            }

            vm.checkAllowEvidence = function ($data, status, object) {
                status.allowEvidence = $data;

                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaNotificationStatusAdded', { statuses: vm.type.statuses });
                }
            }

            vm.checkGlobalStatus = function ($data, status, object) {
                status.globalStatus = $data;

                if(vm.isNew) {
                    $rootScope.$broadcast('AnnexaNotificationStatusAdded', { statuses: vm.type.statuses });
                } else {
                    $rootScope.$broadcast('AnnexaNotificationStatusUpdated', { status: status });
                }
            }

            vm.printYesNo = function (value) {
                if(value == true) {
                    return '<i class="fa fa-check text-success"></i>';
                } else {
                    return '<i class="fa fa-remove text-danger"></i>';
                }
            }
        }],
        bindings: {
            type: '=?'
        }
    })